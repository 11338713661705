<template>
  <div class="page">
    <div class="page-container">
      <div class="page-row">
        <div class="text-wrap" v-for="(item, index) in list"
        :key="index">
          <router-link
            :to="{name: 'Image', query: {imageUrl: item.imageUrl, name: item.name},
              target: '_self' }">
            {{item.name}}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ZzHome',
  computed: {
    list() {
      return [
        { name: '营业执照', imageUrl: '//download-cos.yofish.com/ad-gongjushiyebu/20220920113532370-yyzz.png' },
        { name: '电信与信息服务业务经营许可证', imageUrl: '//download-cos.yofish.com/ad-gongjushiyebu/20220920113516911-xkz.png ' },
      ];
    },
    appName() {
      return this.$route.query?.appName || '有鱼记账';
    },
  },
  mounted() {
    document.title = `${this.appName}资质`;
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  padding-top: 30px;
  .text-wrap {
    background: white;
    border: 0.01rem solid rgb(240, 239, 237);
    padding: 28px;
    /deep/ a {
      color: rgb(102, 102, 102);
      line-height: 0.3rem;
      font-size: 28px;
      text-decoration: none;
      display: block;
    }
  }
}
</style>
